import { Component, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../layouts/security/security.layout.vue'

@Component({
  name: 'GtrError404View'
})
export default class GtrError404View extends Vue {
  created () {
    this.$emit('update:layout', GrtSecurityLayout)
  }
}
